import TriggeredMessageService from '@/services/whatsapp/triggered-message.service.js'
import { UserInboxTriggeredMessage } from '@/models/whatsapp/UserInboxTriggeredMessage'
import EventBus from '@/util/EventBus'
import TextFormatting from '@/components/TextFormatting/TextFormatting.vue'

export default {
  name: 'UserInboxTriggeredMessage',
  components: { TextFormatting },
  props: {
    selectedUserMsisdnConfig: {
      type: String, required: true,
    },
  },
  data () {
    return {
      showModalTriggeredMessage: false,
      items: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      total: 0,
      disabledPagination: true,
      options: {},
      searchTerm: '',
      totalItems: 0,
      userInboxTriggeredMessage: new UserInboxTriggeredMessage(),
      debouncedInput: '',
      timeout: null,
      confirmTriggeredMessageDialog: false,
      user: this.$store.getters['auth/getUser'],
    }
  },
  computed: {
    headers () {
      return [
        {
          text: this.$t('Desencadenador'),
          value: 'trigger',
        },
        {
          text: this.$t('Mensaje'),
          value: 'message',
        },
        {
          text: this.$t('Fecha de creación'),
          value: 'created_at',
        },
        {
          text: this.$t('Acciones'),
          value: 'actions',
          sortable: false,
        },
      ]
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
          this.options.page = 1
        }, 700)
      },
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
  },
  watch: {
    params: {
      handler () {
        this.getTriggeredMessagesData()
      },
      deep: true,
    },
    selectedUserMsisdnConfig: {
      handler: function (selectedUserMsisdnConfig) {
        if (selectedUserMsisdnConfig !== '') {
          this.getTriggeredMessagesData()
        }
      },
      deep: true,
    },
  },
  methods: {
    setTextFormatContext () {
      setTimeout(() => {
        this.$refs.userInboxTriggeredMessageTextFormatting.setContext(this, this.$refs.messageInput.$el.querySelector('textarea'))
      })
    },
    emitChangeTriggeredMessages () {
      EventBus.$emit('changedTriggeredMessages')
    },
    deleteTriggeredMessage () {
      TriggeredMessageService.deleteMessage(this.userInboxTriggeredMessage).then(() => {
        this.userInboxTriggeredMessage = new UserInboxTriggeredMessage()
        this.confirmTriggeredMessageDialog = false
        this.getTriggeredMessagesData()
        this.emitChangeTriggeredMessages()
      })
    },
    preRemoveTriggeredMessage (item) {
      this.userInboxTriggeredMessage = item
      this.confirmTriggeredMessageDialog = true
    },
    newTriggeredMessage () {
      this.userInboxTriggeredMessage = new UserInboxTriggeredMessage()
      this.showModalTriggeredMessage = true
      this.setTextFormatContext()
    },
    saveTriggeredMessage () {
      this.userInboxTriggeredMessage.msisdn = this.selectedUserMsisdnConfig
      TriggeredMessageService.saveMessage(this.userInboxTriggeredMessage).then(
        (response) => {
          this.userInboxTriggeredMessage = new UserInboxTriggeredMessage()
          this.showModalTriggeredMessage = false
          this.getTriggeredMessagesData()
          this.emitChangeTriggeredMessages()
        },
        () => {
          EventBus.$emit('showAlert', 'warning', this.$t('Ocurrió un problema y no se pudo guardar el mensaje'))
        },
      )
    },
    prepareEdit (item) {
      this.userInboxTriggeredMessage = item
      this.showModalTriggeredMessage = true
      this.setTextFormatContext()
    },
    getParams () {
      const page = this.items.length === 1 && this.options.page > 1
        ? this.options.page - 1
        : this.options.page

      const params = {
        page: page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        msisdn: this.selectedUserMsisdnConfig,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },
    getTriggeredMessagesData () {
      const data = this.getParams()
      this.disabledPagination = true
      TriggeredMessageService.getMessagesData(data).then((response) => {
        this.items = response.data
        this.itemsPerPage = parseInt(response.per_page)
        this.page = response.current_page
        this.totalItems = response.total
        this.disabledPagination = false
      })
    },
  },
  mounted () {
    this.getTriggeredMessagesData()
  },
}
